/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import './bootstrap.js';
import { Tab } from 'bootstrap';
import { Modal } from 'bootstrap';
import { createApp } from 'vue';
import moji from 'moji';
import intlTelInput from 'intl-tel-input';
import {createDates,setAsidePrice,__t, setBookCal,setSelectedDate,createPlans,switchDisabledBookFieldset, setSelectedPlan, setcreatePlansEventListener, setCustomerFieldByPassenger} from './components/bookingScripts';
window.intlTelInput = intlTelInput;

/**
 * Next, we will create a fresh Vue application instance. You may then begin
 * registering components with the application instance so they are ready
 * to use in your application's views. An example is included for you.
 */

// const app = createApp({});

// import CalComponent from './components/CalComponent.vue';
import { datetime, RRule, RRuleSet, rrulestr } from 'rrule'


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// Object.entries(import.meta.glob('./**/*.vue', { eager: true })).forEach(([path, definition]) => {
//     app.component(path.split('/').pop().replace(/\.\w+$/, ''), definition.default);
// });

/**
 * Finally, we will attach the application instance to a HTML element with
 * an "id" attribute of "app". This element is included with the "auth"
 * scaffolding. Otherwise, you will need to add an element yourself.
 */

import { TempusDominus, Namespace } from '@eonasdan/tempus-dominus';

import.meta.glob([
    '../img/**',
]);

// app.mount('#app');

// app.component('cal-component', CalComponent);

/**
 * scriptを動的に読み込む関数
 * @param {*} url 
 * @param {*} callback 
 */
function loadScript(url, callback) {
    var script = document.createElement("script");
    script.type = "text/javascript";
    if (script.readyState) {
        // For old versions of IE
        script.onreadystatechange = function () {
            if (script.readyState === "loaded" || script.readyState === "complete") {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {
        // Other browsers
        script.onload = function () {
            callback();
        };
    }
    
    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
}

/**
 * 国際電話番号対応の処理を行う関数
 * @param {HTMLElement} input - 入力フィールド
 * @param {HTMLElement} countryInput - 国コードを保存する入力フィールド
 * @param {Object} inputIti - intlTelInput インスタンス
 */
function handlePhoneNumber(input, countryInput, inputIti, isAlert) {
    // 入力が有効でない場合の処理
    if (!inputIti || !inputIti.isValidNumber() && input.value !== '') {
        if (countryInput) {
            countryInput.value = '';
        }
        if (isAlert) {
            // .invalid-feedback が存在するかを判定
            const itiAncestor = input.closest('.iti');
            const invalidFeedback = itiAncestor.nextElementSibling;
            if (itiAncestor && itiAncestor.nextElementSibling && itiAncestor.nextElementSibling.classList.contains('invalid-feedback')) {
                invalidFeedback.textContent = __t('Invalid Phone number.');
            } else {
                const newInvalidFeedback = document.createElement('div');
                newInvalidFeedback.classList.add('invalid-feedback', 'd-block');
                newInvalidFeedback.textContent = __t('Invalid Phone number.');
                itiAncestor.insertAdjacentElement('afterend', newInvalidFeedback);
            }
        }
        return false;
    } else {
        if (countryInput) {
            if(inputIti.selectedCountryData.iso2 != ''){
                countryInput.value = inputIti.selectedCountryData.iso2.toUpperCase();
            }
        }
        return true;
    }
}

/**
 * 国際電話番号対応
 */
function initializePhoneNumberInputs(inputs, countryInputs, mode = 'init') {
    inputs.forEach((input, index) => {
        

        const inputIti = window.intlTelInput(input, {
            initialCountry: (countryInputs[index].value == '' && mode == 'init'  ? "JP" : countryInputs[index].value),
            autoPlaceholder: "polite",
            containerClass: 'd-block',
            customPlaceholder: function (selectedCountryPlaceholder, selectedCountryData) {
                return __t('e.g. ') + selectedCountryPlaceholder;
            },
            utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@19.5.3/build/js/utils.js",
        });

        if(mode == 'update'){
            handlePhoneNumber(input, countryInputs[index], inputIti, true);
        }else{
            input.addEventListener('blur', function (event) {
                handlePhoneNumber(input, countryInputs[index], inputIti, true);
            });
        }


    });
}



/**
 * 現在の要素から対象要素までを親要素がなくなるまでたどり、その親要素を返す
 * @param {HTMLObjectElement} currentEl
 * @param {HTMLObjectElement} targetEl
 * @returns {HTMLObjectElement}
 */
function findButton(currentEl, targetEl) {
    while (currentEl && !currentEl.matches(targetEl)) {
        currentEl = currentEl.parentElement;
    }
    return currentEl;
}

/**
 * テキスト・テキストエリアを取得し［全角英数字→半角英数字］［半角→全角カタカナ］への変換を行う
 */
const convertTextDefault = (element) => {
    const inputValue = element.value;
    const convertedValue = moji(inputValue).convert('ZE', 'HE').convert('HK', 'ZK').toString();
    element.value = convertedValue;
};

// フォーム内のすべてのテキスト・テキストエリア変化時にテキスト変換
document.addEventListener('change', function(event) {
    if (event.target && event.target.matches('form#book input[type="text"], form#book input[type="tel"], form#book input[type="email"], form#book textarea')) {
        convertTextDefault(event.target);
    }
});

// 入力値を大文字に変換する関数
const convertToUpperCase = (inputElement) => {
    const inputValue = inputElement.value;
    const convertedValue = inputValue.toUpperCase();
    return convertedValue;
};

// フォーム内のローマ字入力（アルファベット）の入力変化時に大文字に変換
document.addEventListener('change', function(event) {
    // 変更された要素が form#book 内の特定の input 要素であるかを確認
    if (event.target && event.target.matches('form#book input[name="name1[]"], form#book input[name="name2[]"], form#book input[name="emergency_name[]"]')) {
        // フォームが変更されたときの処理
        event.target.value = convertToUpperCase(event.target).replace(/[^\x20-\x7E\u0080-\u00FF]/g, '');
    }
});

// カンマ区切り文字列の追加・削除・昇順に並べ替え
function addValueToInput(currentValue, newValue) {
    // 既存の値が空の場合は新しい値を返す
    if (!currentValue) {
        return newValue;
    }
    // 既存の値をカンマで分割して配列にする
    let valuesArray = currentValue.split(',');
    // 既存の値に新しい値が含まれているかチェック
    const isDuplicate = valuesArray.includes(newValue);
    // 重複している場合は削除
    if (isDuplicate) {
        valuesArray = valuesArray.filter(value => value !== newValue);
    } else {
        // 新しい値を追加
        valuesArray.push(newValue);
    }
    // 昇順に並べ替え
    valuesArray.sort((a, b) => new Date(a) - new Date(b));
    // 更新された値をカンマ区切りの文字列にして返す
    return valuesArray.join(',');
}

/**
 * RRULE判定
 */
function isDateInRRule(selectedDate, rruleString) {
    const myrrule = rrulestr(rruleString + ';COUNT=500');
    const occurrences = myrrule.all();
    // 選択された日付をUTC形式に変換
    const selectedUTCDate = new Date(selectedDate + 'T00:00:00Z');
    // 選択された日付がルールに含まれているかどうかを判定
    const isSelectedDateInRule = occurrences.some((occurrence) => {
        return occurrence.toISOString() === selectedUTCDate.toISOString();
    });
    return isSelectedDateInRule;
}

/**
 * ローディング画面
 */

// 読込開始時に 'loading' クラスを追加
document.body.classList.add('loading');
// ローディング画面
var loader = document.getElementById('loader');
window.addEventListener('load', function () {
    // 読み込みが完了したときに 'loading' クラスを削除
    document.body.classList.remove('loading');
});

document.addEventListener('DOMContentLoaded', function () {

    /**
     * 商品画面：RRULE - iCal
     */
    const setTimeRRULE = function () {
        const timeRuleInput = document.getElementById('time_rrule');
        if (!timeRuleInput) return;

        // 実施開始日時: #time_startの要素を取得
        const timeStartInput = document.getElementById('time_start');
        // 繰り返し設定ボタンの要素を取得
        const rruleModalButton = document.getElementById('rruleModalButton');
        // 実施開始日時: #time_startの値が変更されたときのイベント
        timeStartInput.addEventListener('change', function () {
            // #time_startの値が無いか、不適切な場合はボタンを無効にし、それ以外の場合は有効にする
            rruleModalButton.disabled = !timeStartInput.value;
        });
        rruleModalButton.disabled = timeStartInput.readOnly || !timeStartInput.value;

        // RRULE設定ボタンのクリックイベントをリッスン
        document.addEventListener('click', function (event) {
            const targetElement = event.target;
            if (targetElement.classList.contains('action-rrle')) {
                const rruleString = document.getElementById('rruleOutput').textContent;
                // 生成されたRRULEの値をセット
                document.querySelector('textarea[name="time_rrule"]').value = rruleString;
            }
        });

        // モーダル内の入力変更に対応するイベントリスナー
        const modalInputs = document.querySelectorAll('#time_start, #time_end, #rruleModal input, #rruleModal textarea, #rruleModal select');
        modalInputs.forEach(function (input) {
            input.addEventListener('input', function () {
                // 選択内容に基づき、RRULE Stinrgを生成
                const rruleString = generateRRule();
                document.getElementById('rruleOutput').textContent = rruleString;
                // RRULE Stinrgに基づき、日付群を生成
                const rule = new RRule.fromString(rruleString + ';COUNT=100');
                const rruleOccurrences = rule.all();
                const options = { year: 'numeric', month: '2-digit', day: '2-digit', weekday: 'short', timeZoneName: 'short' };
                const formattedOccurrences = rruleOccurrences.map((date) => {
                    return new Intl.DateTimeFormat('ja-JP', options).format(date);
                });
                document.getElementById('rruleOccurrences').textContent = formattedOccurrences.join('\n');
            });
        });

        // RRULE Stringの生成
        function generateRRule() {
            const freq = document.getElementById('freqInput').value;
            const interval = document.getElementById('intervalInput').value;

            // 各曜日の選択状態を取得
            const bymonthlyCheckeds = document.querySelectorAll('input[id^="bymonthly"]:checked');
            const bymonthly = Array.from(bymonthlyCheckeds, bymonthlyChecked => bymonthlyChecked.value);

            // 各月の選択状態を取得
            const byweekdayCheckeds = document.querySelectorAll('input[id^="byweekday"]:checked');
            const byweekday = Array.from(byweekdayCheckeds, byweekdayChecked => byweekdayChecked.value);

            // 実施開始・終了日時を取得
            const dtstart = document.querySelector('input[name="time_start"]').value;
            const until = document.querySelector('input[name="time_end"]').value;

            // 時間をRRULE形式に整形する関数
            const formatDateTime = (input, type) => {
                if (!input) return;
                const date = new Date(input);
                const year = date.getUTCFullYear();
                const month = String(date.getUTCMonth() + 1).padStart(2, '0');
                const day = String(date.getUTCDate()).padStart(2, '0');
                let hours, minutes, seconds;
                if (type === "dtstart") {
                    hours = "00";
                    minutes = "00";
                    seconds = "00";
                } else if (type === "until") {
                    hours = "23";
                    minutes = "59";
                    seconds = "59";
                }
                return `${year}${month}${day}T${hours}${minutes}${seconds}`;
            };

            const dtstart_iso = dtstart ? 'DTSTART;TZID=Asia/Tokyo:' + formatDateTime(dtstart, "dtstart") + "\n" : '';
            const until_iso = until ? 'UNTIL=' + formatDateTime(until, "until") + 'Z;' : '';

            const freqString = freq ? `FREQ=${freq};` : '';

            const intervalString = interval ? `INTERVAL=${interval};` : '';

            // 頻度設定に応じて、月と曜日をRRULE形式に整形
            const bymonthString = freq === 'MONTHLY' && bymonthly.length > 0 ? `BYMONTH=${bymonthly.join(',')};` : '';
            const bydayString = freq === 'WEEKLY' && byweekday.length > 0 ? `BYDAY=${byweekday.join(',')};` : '';

            // RRULE String を生成
            // const rruleString = `FREQ=${freq};INTERVAL=${interval};${bymonthString}${bydayString}${dtstart_iso ? `DTSTART=${dtstart_iso}` : ''}${until_iso ? `;UNTIL=${until_iso}` : ''}`;

            /**
             *  DTSTART;TZID=Asia/Tokyo:20240101T000000
             *  RRULE:FREQ=WEEKLY;UNTIL=20240430T150000Z;BYDAY=MO,TU,WE,TH,FR
             */

            const rruleString = `${dtstart_iso}RRULE:${freqString}${intervalString}${until_iso}${bymonthString}${bydayString}`.replace(/;$/, '');

            // 表示切替
            toggleInputsAbility(freq);

            return rruleString;
        }

        // 頻度設定に応じて、月と曜日入力を表示切替
        function toggleInputsAbility(freqInputValue) {
            const byweekdayModal = document.getElementById('rruleModal_byweekday');
            const bymonthlyModal = document.getElementById('rruleModal_bymonthly');
            if (freqInputValue === 'MONTHLY') {
                // 月毎:MONTHLYの場合
                byweekdayModal.classList.add('d-none');
                bymonthlyModal.classList.remove('d-none');
            } else if (freqInputValue === 'WEEKLY') {
                // 週毎:WEEKLYの場合
                bymonthlyModal.classList.add('d-none');
                byweekdayModal.classList.remove('d-none');
            } else {
                // それ以外の場合
                byweekdayModal.classList.add('d-none');
                bymonthlyModal.classList.add('d-none');
            }
        }
        // 初期切替
        toggleInputsAbility(document.getElementById('freqInput').value);
    };
    setTimeRRULE();

    /**
     * 運休カレンダー生成
     * @param {number} year - 年
     * @param {number} month - 月
     * @param {number} product_id - 商品ID
     */
    const setHolidayCal = (year, month, product_id) => {
        // 商品IDの有無で対応
        let url;
        if (product_id) {
            url = `/mng/products/generate-calendar/${year}/${month}/${product_id}`;
        } else {
            url = `/mng/products/generate-calendar/${year}/${month}`;
        }
        fetch(url, {
            method: 'GET'
        })
            .then(function (response) {
                return response.text();
            })
            .then(function (data) {
                document.getElementById('holidayModalBody').innerHTML = data;
                const holidayEl = document.getElementById('holiday');
                const holidayVal = holidayEl.value;
                const holidayModalTempInputEl = document.getElementById('holidayModalTempInput');
                const holidayModalTempInputVal = holidayModalTempInputEl.value;
                if (holidayModalTempInputVal === '') {
                    holidayModalTempInputEl.value = holidayVal;
                }
                document.querySelectorAll('.d-day-product').forEach(function (element) {
                    const departureDate = element.dataset.departureDate;
                    const departureTime = element.dataset.departureTime;
                    if (holidayModalTempInputEl.value.includes(departureDate + ' ' + departureTime)) {
                        element.classList.remove('btn-info', 'btn-primary');
                        element.classList.add('btn-danger');
                    }
                });
                // 「前の月」ボタンがクリックされたときの処理
                document.querySelector('.action-cal-prev').addEventListener('click', function () {
                    const productIdEl = document.getElementById('id');
                    const product_id = productIdEl.value;
                    const prevYear = (month === 1) ? year - 1 : year;
                    const prevMonth = (month === 1) ? 12 : month - 1;
                    setHolidayCal(prevYear, prevMonth, product_id);
                });
                // 「次の月」ボタンがクリックされたときの処理
                document.querySelector('.action-cal-next').addEventListener('click', function () {
                    const productIdEl = document.getElementById('id');
                    const product_id = productIdEl.value;
                    const nextYear = (month === 12) ? year + 1 : year;
                    const nextMonth = (month === 12) ? 1 : month + 1;
                    setHolidayCal(nextYear, nextMonth, product_id);
                });
                // 各「運航日」がクリックされたときの処理
                document.querySelectorAll('.d-day-product').forEach(function (element) {
                    element.addEventListener('click', function (event) {
                        const dataId = event.currentTarget.dataset.id;
                        const dataName = event.currentTarget.dataset.name;
                        const departureDate = event.currentTarget.dataset.departureDate;
                        const departureTime = event.currentTarget.dataset.departureTime;
                        // 日付をフォーマットする
                        const formattedDepartureDate = new Date(departureDate).toLocaleDateString('ja-JP', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit'
                        });
                        const holidayModalTempInputEl = document.getElementById('holidayModalTempInput');
                        const holidayModalTempInputVal = holidayModalTempInputEl.value;
                        holidayModalTempInputEl.value = addValueToInput(holidayModalTempInputVal, departureDate + ' ' + departureTime);

                        console.log(holidayModalTempInputEl);
                        console.log(departureDate + ' ' + departureTime);
                        // ボタンのクラスを切り替え
                        if (holidayModalTempInputEl.value.includes(departureDate + ' ' + departureTime)) {
                            element.classList.remove('btn-info', 'btn-primary');
                            element.classList.add('btn-danger');
                        } else if (element.classList.contains('has-booking')) {
                            element.classList.remove('btn-danger');
                            element.classList.add('btn-info');
                        } else {
                            element.classList.remove('btn-danger');
                            element.classList.add('btn-primary');
                        }
                    });
                });
                // 保存ボタンがクリックされたときの処理
                const saveBtn = document.querySelector('#holidayModal .action-save');
                saveBtn.addEventListener('click', function (event) {
                    const holidayModalTempInputEl = document.getElementById('holidayModalTempInput');
                    if (holidayEl && holidayModalTempInputEl) {
                        holidayEl.value = holidayModalTempInputEl.value;
                    }
                });

            })
            .catch(function (error) {
                console.error(error);
            });
    };

    /**
     * 商品新規作成時は運休設定を無効化
     */
    const btnDateHolidayModalEl = document.getElementById('holidayModalButton');
    const productIdEl = document.getElementById('id');
    if (btnDateHolidayModalEl && productIdEl) {
        const product_id = productIdEl.value;
        if (!product_id) {
            btnDateHolidayModalEl.disabled = true;
        } else {
            btnDateHolidayModalEl.disabled = false;
        }
        btnDateHolidayModalEl.addEventListener('click', function () {
            const now = new Date();
            const year = now.getFullYear();
            const month = now.getMonth() + 1;
            setHolidayCal(year, month, product_id);
        });
    }

    /**
     * 商品作成
     */
    const date_holiday_cal = document.getElementById('holidayCal');
    if (date_holiday_cal) {
        /**
         * 商品作成：運休日設定
         */
        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth() + 1; // 月は0から始まるため、1を追加して1月が1となるように調整
        const productIdEl = document.getElementById('id');
        const product_id = productIdEl.value;
        setHolidayCal(year, month, product_id);
    }

    /**
     * 予約一覧ページの「支払い状況更新」ボタン
     */

    // 支払い状況更新ボタンがクリックされた時の処理
    document.addEventListener('click', function (event) {
        // クリックされた要素が"action-update-payment-status"クラスを持つボタンであるかを確認
        if (event.target && event.target.classList.contains('action-update-payment-status')) {

            // フォームデータを作成
            var formData = new FormData();
            formData.append('_token', document.querySelector('input[name="_token"]').value); // CSRFトークンを追加

            // POSTリクエストを送信
            fetch('/mng/books/bulk-update-payment-status', {
                method: 'POST', // POSTメソッドを使用
                body: formData
            })
                .then(response => {
                    if (response.ok) {
                        // 成功時の処理（ページをリロードする等）
                        window.location.reload();
                    } else {
                        // エラー時の処理
                        console.error('Failed to update payment status');
                    }
                })
                .catch(error => {
                    // エラー時の処理
                    console.error('Error occurred:', error);
                });
        }
    });

    /**
     * 予約作成
     */
    const bookScreen = document.getElementById('book');
    if (bookScreen) {

        const url = new URL(location.href);
        const params = new URLSearchParams(url.search);
        let serviceSelected = 1;
        if (params.has('service')) {
            serviceSelected = params.get('service');
        }
        serviceSelect(serviceSelected, 'load');

        /**
         * 予約情報：ページ離脱時の対応
         */
        /* ★★★★★本番環境で有効★★★★★
        window.addEventListener("beforeunload", (event) => {
            event.preventDefault();
            event.returnValue = 'Check';
        });
        */

        /**
         * アクティブなタブ以外を全てのタブボタンを無効化する
         */
        const setDisableAllTabs = function () {
            
            const tabLinks = document.querySelectorAll('#bookNavTab .nav-link');
            tabLinks.forEach(tabLink => {
                const activeTabLink = tabLink.classList.contains('active');
                if (!activeTabLink) {
                    tabLink.setAttribute('disabled', 'true');
                }
            });
        }

        /**
         * 予約情報：タブ遷移（`次へ・戻る`）ボタンがクリックされた時の処理
         */
        bookScreen.addEventListener('click', function (event) {
            // ボタンを取得
            const targetEl = findButton(event.target, 'button') ?? findButton(event.target, 'input');
            // すべてのタブ
            const tabLinks = document.querySelectorAll('#bookNavTab .nav-link');
            // アクティブなタブ
            const activeTabLink = document.querySelector('#bookNavTab .nav-link.active');
            // アクティブなタブの位置
            const activeTabIndex = Array.from(tabLinks).indexOf(activeTabLink);
            // タブ、「次へ・戻る」ボタンの有効性を確認
            if (!(activeTabLink && targetEl)) return;
            if (!targetEl.disabled) {
                // サービス選択をされた場合
                if(targetEl.classList.contains('service-select')){
                    serviceSelect(targetEl.value);
                }
                // 「次へ」または「戻る」ボタンがクリックされた場合
                if (targetEl.classList.contains('action-next')) {
                    // 次のタブの位置
                    const nextTabIndex = (activeTabIndex + 1) % tabLinks.length;
                    // 次のタブを取得
                    const nextTabLink = tabLinks[nextTabIndex];
                    const nextTabTrigger = new Tab(nextTabLink);
                    // 無効なタブを有効化
                    nextTabLink.removeAttribute('disabled');
                    // タブを開く
                    nextTabTrigger.show();
                    // ボタンが「お支払い」の場合、お支払い以外のタブを無効化
                    const isActionPayment = targetEl.classList.contains('action-payment');
                    if (isActionPayment) {
                        setDisableAllTabs();
                        window.setTimeout(function () {
                            document.book.submit();
                        }, 250);
                    }
                } else if (targetEl.classList.contains('action-prev')) {
                    // 前のタブの位置
                    const prevTabIndex = (activeTabIndex - 1) % tabLinks.length;
                    // 前のタブを取得
                    const prevTabLink = tabLinks[prevTabIndex];
                    const prevTabTrigger = new Tab(prevTabLink);
                    // 無効なタブを有効化
                    prevTabLink.removeAttribute('disabled');
                    // タブを開く
                    prevTabTrigger.show();
                }
                if (['action-next', 'action-prev'].some(cls => targetEl.classList.contains(cls))) {
                    // ページの先頭にスクロール
                    window.scrollTo(0, 0);
                }
            }
        });

        function serviceSelect(serviceSelected = 1, mode = 'update') {
            const url = new URL(location.href);
            const params = new URLSearchParams(url.search);

            if (params.has('service')) {
                if (params.get('service') !== serviceSelected) {
                    params.set('service', serviceSelected);
                }
            } else {
                // 元のURLにserviceパラメータが含まれていない場合、新しく追加する
                params.append('service', serviceSelected);
            }

            let lang = 'ja';

            if (params.has('locale')) {
                lang = params.get('locale');
            }

            url.search = params.toString();
            const csrfMetaTag = document.querySelector('meta[name="csrf-token"]');
            if (csrfMetaTag) {
                const csrf = csrfMetaTag.getAttribute('content');
                history.pushState(null, null, url.toString());

                fetch('/set-service', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': csrf,
                        'X-Requested-With': 'XMLHttpRequest', // LaravelのAjaxリクエストを示すヘッダー
                        'Accept-Language': lang // ページの言語を送信
                    },
                    credentials: 'same-origin', // クッキーを送信するためのオプション
                    body: JSON.stringify({
                        service: serviceSelected,
                        locale: lang
                    })
                })
                .then(response => response.text())
                .then(async html => {
                    // 取得したHTMLを追加する
                    if (mode == 'update') {
                        const container = document.getElementById('bookingSelects'); // HTMLを追加する要素のIDを指定してください
                        container.innerHTML = html;
                    }

                        if (serviceSelected == 1) {
                            serviceSpecial();
                        } else if (serviceSelected == 2) {
                            await serviceScenic();
                        }
                        serviceCommon();
                })
                .catch(error => console.error('Error:', error));

                if(mode == 'update'){
                    fetch('/set-customer', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRF-TOKEN': csrf,
                            'X-Requested-With': 'XMLHttpRequest', // LaravelのAjaxリクエストを示すヘッダー
                            'Accept-Language': lang // ページの言語を送信
                        },
                        credentials: 'same-origin', // クッキーを送信するためのオプション
                        body: JSON.stringify({
                            service: serviceSelected,
                            locale: lang
                        })
                    })
                    .then(response => response.text())
                    .then(html => {
                        // 取得したHTMLを追加する
                        const container = document.getElementById('customerInfoPane'); // HTMLを追加する要素のIDを指定してください
                        container.outerHTML = html;
                        if (serviceSelected == 1) {
                            serviceSpecial();
                        } else if (serviceSelected == 2) {
                            serviceScenic();
                        }
                        serviceCommon();
                    })
                    .catch(error => console.error('Error:', error));
                    
                }

            }

        }

        /**
         * ==========================================================================================
         * 言語選択・支払方法案内
         * ==========================================================================================
         */

        // モーダルの表示状態をセッションストレージから取得
        const modalShown = sessionStorage.getItem('firstRunBookingModalShown');

        // モーダルがまだ表示されていない場合
        if (!modalShown) {
            // モーダルを表示
            const firstRunBookingModal = new Modal(document.getElementById('firstRunBookingModal'));
            firstRunBookingModal.show();

            // ボタンがクリックされたときの処理
            const actionButtons = document.querySelectorAll('#firstRunBookingModal .action-close');
            actionButtons.forEach(function(button) {
                button.addEventListener('click', function () {
                    // ボタンの attr-locale 属性の値を取得
                    const locale = this.getAttribute('attr-locale');
                    // セッションストレージに表示状態を保存
                    sessionStorage.setItem('firstRunBookingModalShown', 'true');
                    // セッションストレージに言語を保存
                    sessionStorage.setItem('locale', locale);
                    // モーダルを非表示にする
                    firstRunBookingModal.hide();
                    // URLに言語パラメータを追加
                    let localeUrl = '/?locale=' + locale;
                    // 新しいURLにリダイレクト
                    window.location.href = localeUrl;   
                });
            });
        }

        /**
         * ==========================================================================================
         * サービス選択
         * ==========================================================================================
         */

        /**
         * サービス：初期表示：URLパラメータに基づくサービス自動選択
         */
        const setServiceParam = function () {
            // ページ読み込み時にGETパラメータを取得
            const urlParams = new URLSearchParams(window.location.search);
            const selectedService = urlParams.get('service');
            // 該当するラジオボタンをチェック
            if (selectedService !== null) {
                const radio = document.querySelector(`input[name="service"][value="${selectedService}"]`);
                if (radio) {
                    radio.checked = true;
                }
            } else {
                // serviceパラメータが指定されていない場合、1番目のラジオボタンをデフォルトでチェック
                const defaultRadio = document.querySelector('input[name="service"]');
                if (defaultRadio) {
                    defaultRadio.checked = true;
                }
            }
        };
        setServiceParam();

        /**
         * 予約情報：初期表示：URLパラメータに基づく出発地・到着地自動選択
         */
        const setFromtOParam = function () {
            // ページ読み込み時にGETパラメータを取得
            console.log('setFromtOParam');
            const urlParams = new URLSearchParams(window.location.search);
            const selectedFrom = urlParams.get('from');
            const selectedTo = urlParams.get('to');
            // 出発地の選択
            if (selectedFrom) {
                const searchFromOut = document.getElementById('search_from_out');
                if (searchFromOut) {
                    // URLパラメータで指定された値を選択状態にする
                    searchFromOut.value = selectedFrom;
                }
            }
            // 到着地の選択
            if (selectedTo) {
                const searchToOut = document.getElementById('search_to_out');
                if (searchToOut) {
                    // URLパラメータで指定された値を選択状態にする
                    searchToOut.value = selectedTo;
                }
            }
        };
        //        setFromtOParam();

        /**
         * ==========================================================================================
         * 予約情報
         * ==========================================================================================
         */

        const serviceCommon = () => {
            
            /**
             * ==========================================================================================
             * 言語処理
             * ==========================================================================================
             */

            let lang = 'ja';
            const url = new URL(location.href);
            const params = new URLSearchParams(url.search);
            if (params.has('locale')) {
                lang = params.get('locale');
            }
            
            /**
             * ==========================================================================================
             * お客様情報
             * ==========================================================================================
             */

            /**
             * 予約情報：`お客様情報`を人数分だけ出力
             */
            const productScenic = document.querySelector('input[name="product_scenic"]:checked');
            let aircraft_id =1;
            if (productScenic) {
                aircraft_id  = productScenic.getAttribute('data-aircraft_id');
            }

            setCustomerFieldByPassenger(aircraft_id);

            /**
             * 予約情報：[EL] 搭乗数を変更時に「`お客様情報`を人数分だけ出力」を呼び出す
             */
            document.addEventListener('change', function(event) {
                if (event.target && event.target.id === 'passengers') {
                    setCustomerFieldByPassenger(aircraft_id);
                }
            });

            /**
             * お客様情報：電話番号対応
             */

            // 通常の電話番号入力
            const inputPhones = document.querySelectorAll('input[name="phone[]"]');
            const phoneCountryInputs = document.querySelectorAll('input[name="phone_country[]"]');
            initializePhoneNumberInputs(inputPhones, phoneCountryInputs);

            // 緊急電話番号入力
            const inputEmergencyPhones = document.querySelectorAll('input[name="emergency_phone[]"]');
            const emergencyPhoneCountryInputs = document.querySelectorAll('input[name="emergency_phone_country[]"]');
            initializePhoneNumberInputs(inputEmergencyPhones, emergencyPhoneCountryInputs);
            
            // お客様情報：座席選択用のラジオボタンを取得
            /*
            const seatRadios = document.querySelectorAll('#customerInfoPane .book__customer__seat input[type="radio"]');
            seatRadios.forEach(function (radio) {
                // ラジオボタンの変更イベントにリスナーを追加
                radio.addEventListener('change', function () {
                    // 選択された座席情報を取得
                    const selectedSeat = this.value;
                    const seatName = this.id;
                    const seatIndex = this.getAttribute('data-index');

                    // 重複をチェックする処理を実装
                    const otherSeatRadios = document.querySelectorAll(`#customerInfoPane .book__customer__seat input[type="radio"]:not([data-index="${seatIndex}"])`);
                    otherSeatRadios.forEach((otherRadio) => {
                        if (otherRadio.checked && otherRadio.value === selectedSeat) {
                            // 既に同じ座席が選択中の場合の確認メッセージ
                            const confirmationMessage = "ご指定の座席はすでに選択中です。そちらの選択が解除されます。よろしいですか？";

                            // ユーザーが確認メッセージにOKを選択した場合
                            if (confirm(confirmationMessage)) {
                                // 選択中の座席を取り消す
                                otherRadio.checked = false;
                            } else {
                                // 現在の選択を解除する
                                radio.checked = false;
                            }
                            // 座席の状態を更新
                            changeSeatRadioDisability();
                        }
                    });

                    // 選択されたラジオボタンがチェックされている場合、座席の状態を更新
                    if (this.checked) changeSeatRadioDisability();
                });
            });*/

            /**
             * お客様情報：座席の有効・無効を制御する関数
             */
            /*
            const changeSeatRadioDisability = () => {
                const allSeatRadios = document.querySelectorAll(`#customerInfoPane .book__customer__seat input[type="radio"]`);
                const reversedSeatRadios = Array.from(allSeatRadios).reverse();
                // いったんすべてのラジオボタンを有効化
                allSeatRadios.forEach(function (thisRadio) {
                    // 値が空の場合を除く
                    if (thisRadio.value !== '') {
                        thisRadio.removeAttribute('disabled');
                    }
                });
                // チェック済みの同値の他ラジオボタンを無効化
                for (const thisRadio of reversedSeatRadios) {
                    if (thisRadio.checked) {
                        // 同じ値のものを探して無効化する
                        const thisSeatIndex = thisRadio.getAttribute('data-index');
                        for (const thatRadio of reversedSeatRadios) {
                            const thatSeatIndex = thatRadio.getAttribute('data-index');
                            if (thatRadio.value === thisRadio.value && !thatRadio.checked && (thatSeatIndex > thisSeatIndex)) {
                                thatRadio.setAttribute('disabled', true);
                            }
                        }
                    }
                }
            };*/

            /**
             * お客様情報：同一住所・連絡先入力省略ボタン
             */
            const setSameAddressButton = () => {
                const customerInfoPane = document.querySelector('#customerInfoPane');
                const inputNames = ['zipcode[]', 'country[]', 'region[]', 'city[]', 'street[]', 'apt[]', 'phone_country[]', 'phone[]', 'email_address[]'];
                const customerDataArray = [];

                // お客様情報を収集する関数
                const collectCustomerData = (customer) => {
                    const customerData = {};
                    inputNames.forEach((inputName) => {
                        customerData[inputName] = customer.querySelector(`[name="${inputName}"]`);
                    });
                    return customerData;
                };

                // 対象のお客様情報を更新する関数
                const updateTargetCustomerData = (referenceData, targetData) => {
                    inputNames.forEach((inputName) => {
                        targetData[inputName].value = referenceData[inputName].value;
                    });
                };

                // お客様情報を収集
                const customers = customerInfoPane.querySelectorAll('.book__customer');
                customers.forEach((customer) => {
                    customerDataArray.push(collectCustomerData(customer));
                });

                // 同一住所入力省略ボタンのクリックイベントをリッスン
                customerInfoPane.addEventListener('click', (event) => {
                    const clickedButton = event.target.closest('button.action-same-address');
                    if (clickedButton) {
                        // クリックされたボタンの親要素である「お客様情報枠」を取得
                        const customer = clickedButton.closest('.book__customer');
                        // 何番目の「お客様情報枠」か取得
                        const customerIndex = Array.from(customerInfoPane.querySelectorAll('.book__customer')).indexOf(customer);
                        // 1人目のお客様と、対象のお客様のデータを取得
                        const referenceData = customerDataArray[0];
                        const targetData = customerDataArray[customerIndex];
                        // 1人目のお客様の値を、対象のお客様情報にコピー
                        updateTargetCustomerData(referenceData, targetData);
                        // 通常の電話番号入力
                        const inputPhones = document.querySelectorAll('input[name="phone[]"]');
                        const phoneCountryInputs = document.querySelectorAll('input[name="phone_country[]"]');
                        initializePhoneNumberInputs(inputPhones, phoneCountryInputs, 'update');
                    }
                });

                

            };

            // 同一住所入力省略ボタンを初期化
            setSameAddressButton();

            /**
             * お客様情報：同一緊急連絡先入力省略ボタン
             */
            const setSameEmergencyButton = () => {
                const customerInfoPane = document.querySelector('#customerInfoPane');
                const inputNames = ['emergency_name[]', 'emergency_name_jp[]', 'emergency_relationship[]','emergency_phone_country[]', 'emergency_phone[]'];
                const customerDataArray = [];
                // 緊急連絡先を収集する関数
                const collectCustomerData = (customer, inputNames) => {
                    const customerData = {};
                    inputNames.forEach((inputName) => {
                        customerData[inputName] = customer.querySelector(`[name="${inputName}"]`);
                    });
                    return customerData;
                };
                // 対象の緊急連絡先を更新する関数
                const updateTargetCustomerData = (referenceData, targetData, inputNames) => {
                    inputNames.forEach((inputName) => {
                        targetData[inputName].value = referenceData[inputName].value;
                    });
                };
                // 緊急連絡先を収集
                const customers = customerInfoPane.querySelectorAll('.book__customer');
                customers.forEach((customer) => {
                    customerDataArray.push(collectCustomerData(customer, inputNames));
                });
                // 同一緊急連絡先入力省略ボタンのクリックイベントをリッスン
                customerInfoPane.addEventListener('click', (event) => {
                    const clickedButton = event.target.closest('button.action-same-emergency');
                    if (clickedButton) {
                        // クリックされたボタンの親要素である「お客様情報枠」を取得
                        const customer = clickedButton.closest('.book__customer');
                        // 何番目の「お客様情報枠」か取得
                        const customerIndex = Array.from(customerInfoPane.querySelectorAll('.book__customer')).indexOf(customer);
                        // 1人目のお客様と、対象のお客様のデータを取得
                        const referenceData = customerDataArray[0];
                        const targetData = customerDataArray[customerIndex];
                        // 1人目のお客様の値を、対象のお客様情報にコピー
                        updateTargetCustomerData(referenceData, targetData, inputNames);
                        // 緊急電話番号入力
                        const inputEmergencyPhones = document.querySelectorAll('input[name="emergency_phone[]"]');
                        const emergencyPhoneCountryInputs = document.querySelectorAll('input[name="emergency_phone_country[]"]');
                        initializePhoneNumberInputs(inputEmergencyPhones, emergencyPhoneCountryInputs,'update');
                    }
                });
            };

            // 同一住所入力省略ボタンを初期化
            setSameEmergencyButton();
    
            /**
             * 郵便番号処理
             */
            if( lang === 'ja' ) {
                loadScript("https://yubinbango.github.io/yubinbango/yubinbango.js", function () {
                    if ( typeof YubinBango !== 'undefined' && YubinBango.MicroformatDom ) {

                        new YubinBango.MicroformatDom();

                        /**
                         * yubinbango.js実行後:
                         * 住所最適化（番地・建物名に「〇丁目」が含まれていた場合に町域・丁目に移動）
                         * 同時に入力値をすべて半角英数字に変更
                         * @param {HTMLElement} aptInput 番地・建物名入力要素
                         */
                        const adjustAddressChoume = (aptInput) => {
                            window.setTimeout(function () {
                                // 丁目が含まれているかチェック
                                const inputValue = aptInput.value;
                                if (inputValue !== '') {
                                    const indexOfCho = inputValue.indexOf('丁目');
                                    console.log(inputValue);
                                    if (indexOfCho !== -1) {
                                        // 丁目が含まれている場合、丁目以前の部分を取得
                                        const streetValue = inputValue.substring(0, indexOfCho + 2);
                                        // 丁目以前の部分を削除
                                        const aptValue = inputValue.substring(indexOfCho + 2);
                                        aptInput.value = aptValue;
                                        // 対応する番地・建物名の要素を取得
                                        const streetInput = aptInput.closest('fieldset').querySelector('input[name^="street[]"]');
                                        if (streetInput) {
                                            // 番地・建物名の値を更新
                                            streetInput.value = streetInput.value + streetValue;
                                        }
                                    }
                                    // フォーム内のすべてのテキスト・テキストエリア変化時にテキスト変換
                                    document.querySelectorAll('form#book input[type="text"], form#book input[type="tel"], form#book input[type="email"], form#book textarea').forEach(element => {
                                        convertTextDefault(element);
                                    });
                                }
                            }, 250);
                        };

                        /**
                         * 郵便番号入力後:
                         * 住所最適化 と 入力フォーカスの移動
                         */
                        const zipcodeInputs = document.querySelectorAll('input[name^="zipcode[]"]');
                        zipcodeInputs.forEach(function (zipcodeInput) {
                            zipcodeInput.addEventListener(
                                "keyup",
                                function (e) {
                                    if (this.value.toString().match(/^[0-9]{7}$/) || this.value.toString().match(/^\d{3}-?\d{4}$/)) {
                                        this.closest('fieldset').querySelector('input[name^="street[]"]').focus();
                                        adjustAddressChoume(this.closest('fieldset').querySelector('input[name^="apt[]"]'));
                                    }
                                },
                                !1
                            );                        
                        });

                    }
                });
            }

            /**
             * 誕生日
             */
            const setBirthdatePicker = function () {
                const birthdateInputs = document.querySelectorAll('input[name="birthdate[]"]');
                birthdateInputs.forEach(input => {
                    new TempusDominus(input, {
                        defaultDate: input.value ? input.value : '1990/01/01',
                        allowInputToggle: true,
                        restrictions: {
                            maxDate: new Date(),
                        },
                        display: {
                            theme: 'light',
                            viewMode: 'years',
                            components: {
                                calendar: true,
                                date: true,
                                month: true,
                                year: true,
                                decades: true,
                                clock: false,
                                hours: false,
                                minutes: false,
                                seconds: false,
                            },
                        },
                        localization: {
                            locale: currentLocale === 'ja' ? 'ja-JP' : 'en',
                            format: 'yyyy/MM/dd',
                            dayViewHeaderFormat: { month: 'long', year: 'numeric' },
                        },
                    });
                });
            };
            setBirthdatePicker();

        }

        const serviceScenic = async () => {
            setBookCal('scenic');//OK

            /**
             * 予約作成：予約日の選択肢（ボタン）を生成
             */

            createDates('scenic');//OK

            /**
             * 予約情報：[EL] 予約日の選択肢（ボタン）選択時の処理
             */
            setSelectedDate('scenic'); //OK

            /**
             * 予約情報：プランリストを生成
             */
            await createPlans('scenic'); //OK
            
            /**
             * 予約情報：[EL] プラン選択時の処理
             */
            setSelectedPlan('scenic'); //OK

            /**
             * 予約情報：[EL] 往路：「路線」「人数」「日付」変更時にプランを検索
             */
            setcreatePlansEventListener('scenic');

            /**
             * プラン選択時のスクロール処理
             */
            document.addEventListener('change', function(event) {
                if (event.target.matches('input[name="product_scenic"]')) {
                    var cancelPolicyElement = document.getElementById('cancelpolicy');
                    var offsetTop = cancelPolicyElement.offsetTop;
                    window.scrollTo({
                        top: offsetTop,
                        behavior: 'smooth'
                    });
                }
            });

            /**
             * 予約情報：`同意事項`選択時の処理
             */
            const checkboxes = document.querySelectorAll('input[type="checkbox"][name^="is_agree_"]');
            // 同意事項のチェックボックスに対しイベントリスナーを追加
            checkboxes.forEach(checkbox => {
                checkbox.addEventListener('change', () => {
                    // 表示の更新
                    switchDisabledBookFieldset('scenic');
                });
            });

            window.setTimeout(function () {
                setAsidePrice('scenic');
            }, 500);

        }
        
        const serviceSpecial = () => {
            // 予約情報：往路・復路 選択要素を取得
            const outFromEl = document.getElementById('search_from_out');
            const outToEl = document.getElementById('search_to_out');
            const inFromEl = document.getElementById('search_from_in');
            const inToEl = document.getElementById('search_to_in');

            // 予約情報：往路・復路 選択要素が存在するか確認
            if (outFromEl && outToEl && inFromEl && inToEl) {
                // 出発地が変更時のイベントリスナー
                outFromEl.addEventListener('change', function () {
                    // 出発地の選択肢を更新
                    updateOutOptions(outFromEl, outToEl, true);
                    // 帰りの出発地と到着地の選択値を更新
                    updateInByOutOptions(outFromEl, outToEl, inFromEl, inToEl);
                });
                // 到着地が変更時のイベントリスナー
                outToEl.addEventListener('change', function () {
                    // 帰りの出発地と到着地の選択値を更新
                    updateInByOutOptions(outFromEl, outToEl, inFromEl, inToEl);
                });
                // 初期化
                updateOutOptions(outFromEl, outToEl, false);
            }

            /**
             * 予約情報：出発・到着地 選択処理
             * @param {HTMLSelectElement} fromSelect - 往路出発地
             * @param {HTMLSelectElement} toSelect - 往路到着地
             * @param {boolean} isReset - 初期化フラグ
             */
            function updateOutOptions(fromSelect, toSelect, isReset) {
                // 往路到着地を初期化（0番目を選択）
                if (isReset) toSelect.selectedIndex = 0;
                // リセット
                for (let i = 0; i < toSelect.options.length; i++) {
                    toSelect.options[i].disabled = false;
                }
                // 往路出発地を往路到着地で選択を無効化
                const selectedValue = fromSelect.value;
                if (selectedValue) {
                    for (let j = 0; j < toSelect.options.length; j++) {
                        if (toSelect.options[j].value === selectedValue) {
                            toSelect.options[j].disabled = true;
                            break;
                        }
                    }
                }
            }

            /**
             * 予約情報：往路選択を反転し復路選択に適用する処理
             * @param {HTMLSelectElement} outFromEl - 往路出発地
             * @param {HTMLSelectElement} outToEl - 往路到着地
             * @param {HTMLSelectElement} inFromEl - 復路出発地
             * @param {HTMLSelectElement} inToEl - 復路到着地
             */
            function updateInByOutOptions(outFromEl, outToEl, inFromEl, inToEl) {
                // 往路の出発・到着地を逆転して、復路に設定
                inToEl.value = outFromEl.value;
                inFromEl.value = outToEl.value;
            }


            window.setTimeout(function () {
                setAsidePrice(null);
            }, 500);

            /**
             * ==========================================================================================
             * 予約情報
             * ==========================================================================================
             */

            /**
             * 「復路を利用する」変更時のイベントリスナー
             */
            document.addEventListener('change', function(event) {
                if (event.target && event.target.matches('[name="use_in_bound"]')) {
                    switchDisabledBookFieldset('special');
                    setAsidePrice(null);
                }
            });
                    switchDisabledBookFieldset('special');

            /**
             * 予約情報：カレンダー表示
             */
            setBookCal('special');

            /**
             * 予約作成：予約日の選択肢（ボタン）を生成
             */

            createDates('out');
            createDates('in');

            /**
             * 予約情報：[EL] 予約日の選択肢（ボタン）選択時の処理
             */
            setSelectedDate('out');
            setSelectedDate('in');

            /**
             * 予約情報：プランリストを生成
             */
            createPlans('out');
            createPlans('in');

            /**
             * 予約情報：[EL] プラン選択時の処理
             */
            setSelectedPlan('out');
            setSelectedPlan('in');

            /**
             * 予約情報：[EL] 往路：「路線」「人数」「日付」変更時にプランを検索
             */
            setcreatePlansEventListener('out');
            setcreatePlansEventListener('in');

            /**
             * 島民割引選択時の処理
             */
            for (let i = 0; i < 10; i++) {
                // 島民割引
                const discountName = `is_discount_1[${i}][]`;
                const discountRadios = document.querySelectorAll(`input[name="${discountName}"]`);
                // ラジオボタンにイベントリスナーを追加
                discountRadios.forEach(discountRadio => {
                    discountRadio.addEventListener('change', function () {
                        setAsidePrice(null);
                    });
                });
            }

            const checkboxes = document.querySelectorAll('input[type="checkbox"][name^="is_agree_"]');
            // 同意事項のチェックボックスに対しイベントリスナーを追加
            checkboxes.forEach(checkbox => {
                checkbox.addEventListener('change', () => {
                    // 表示の更新
                    switchDisabledBookFieldset(null);
                });
            });

        }

    }

    /**
     * ==========================================================================================
     * 予約一覧
     * ==========================================================================================
     */

    /**
     * 予約取り消しボタン
     */
    const bookCancelConfirmModalScreen = document.getElementById('bookCancelConfirmModal');
    if (bookCancelConfirmModalScreen) {
        const cancelButtons = document.querySelectorAll('.action-cancel-dialog');
        // 各ボタンに対して処理を追加
        cancelButtons.forEach((cancelButton) => {
            cancelButton.addEventListener('click', function () {
                const deleteForm = document.getElementById('form-books-delete');
                const priceInput = document.getElementById('priceInput');
                const refundCheckbox = document.getElementById('refundCheckbox');
                const refundInput = document.getElementById('refundInput');
                const dataHref = this.getAttribute('data-href');
                const dataPrice = this.getAttribute('data-price');
                const dataRefund = this.getAttribute('data-refund');
                deleteForm.action = dataHref;
                priceInput.value = dataPrice;
                refundCheckbox.checked = false;
                refundInput.value = dataRefund;
                refundInput.setAttribute('readonly', 'readonly');
            });
        });
        // 返金率の編集可否を切り替える
        const refundCheckbox = document.getElementById('refundCheckbox');
        const refundInput = document.getElementById('refundInput');
        refundInput.setAttribute('readonly', 'readonly');
        refundCheckbox.addEventListener('change', function () {
            if (refundCheckbox.checked) {
                // チェックボックスがオンの場合、readonlyを無効化
                refundInput.removeAttribute('readonly');
            } else {
                // チェックボックスがオフの場合、readonlyを有効化
                refundInput.setAttribute('readonly', 'readonly');
            }
        });

    }


});