import { TempusDominus, Namespace } from '@eonasdan/tempus-dominus';

/**
 * 予約作成：予約日の選択肢（ボタン）を生成
 */
const createDates = (prefix) => {
    const parser = new DOMParser();
    // 選択中日付を取得
    const departure_date = getValByName('departure_date_' + prefix);
    const departure_date_val = new Date(departure_date);
    // 選択中日付を一時管理用の入力に保存
    const departure_date_begin = document.getElementsByName('departure_date_' + prefix + '_begin');
    if (departure_date_begin) {
        departure_date_begin[0].value = departure_date;
    }
    // 選択領域を初期化
    const departureDatesContainer = document.getElementById('departure_date_' + prefix + 's');
    departureDatesContainer.innerHTML = '';
    // 日付ボタンHTMLを生成
    const radioHtml = '<input type="radio" class="btn-check" id="" name="departure_date_btn_' + prefix + '" autocomplete="off" value="">';
    const labelHtml = '<label class="btn action-select-date" for=""></label>'
    const radioDoc = parser.parseFromString(radioHtml, "text/html");
    const labelDoc = parser.parseFromString(labelHtml, "text/html");
    const radioTemplate = radioDoc.body.children;
    const labelTemplate = labelDoc.body.children;
    // ボタングループ内側に日付ボタンを5つ設置する
    const numberOfDates = 5;
    for (let i = 0; i < numberOfDates; i++) {
        // 新しい日付を作成
        const newDate = new Date(departure_date_val);
        newDate.setDate(newDate.getDate() + i);
        // input要素のクローンを作成
        const radioClone = radioTemplate[0].cloneNode(true);
        radioClone.id = `departure_${prefix}_date_${i}`;
        radioClone.value = newDate.toISOString().split('T')[0];
        // label要素のクローンを作成
        const labelClone = labelTemplate[0].cloneNode(true);
        labelClone.htmlFor = `departure_${prefix}_date_${i}`;
        // 日付をローカライズする
        const month = (currentLocale === 'ja') ? (newDate.getMonth() + 1) : newDate.toLocaleString('en', { month: 'short' });
        const date = newDate.getDate();
        const dayOfWeek = getDayOfWeek(newDate.getDay(), currentLocale);
        // ローカルによって表示形式を切り替える
        const formattedDate = (currentLocale === 'ja') ? `${month}月${date}日 (${dayOfWeek})` : `${dayOfWeek}, ${month} ${date}`;
        labelClone.innerHTML = `<i class="fa-solid fa-plane"></i> <span>${formattedDate}</span>`;
        // 最初の日付を選択
        if (i === 0) radioClone.checked = true;
        // 選択領域の内側に追加
        departureDatesContainer.appendChild(radioClone);
        departureDatesContainer.appendChild(labelClone);
    }
};

/**
 * 指定した要素の値を取得
 * @param {string} name - 取得する要素のname属性値
 * @returns {string} - 取得した要素の値
 */
function getValByName(name) {
    const elements = document.getElementsByName(name);
    if (elements.length > 0) {
        if (elements[0].value !== '') {
            return elements[0].value;
        } else {
            return null;
        }
    } else {
        return null;
    }
}

/**
 * 数字から曜日名取得
 * @param {Number} dayIndex 
 * @returns 
 */
function getDayOfWeek(dayIndex, locale) {
    const daysOfWeek = (locale == 'ja') ? ['日', '月', '火', '水', '木', '金', '土'] : ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return daysOfWeek[dayIndex];
}


/**
 * 料金情報更新
 */

const setAsidePrice = (prefix) => {

    if(prefix == 'scenic'){
        setAsidePriceScenic();
    }else{
        setAsidePriceSpecial();
    }

};

const setAsidePriceScenic = () => {

    const passengersSelect = document.getElementById('passengers');
    const selectedPassengers = parseInt(passengersSelect.value);

    // 選択プラン状況取得
    const productScenic = document.querySelector(`input[name="product_scenic"]:checked`);
    //const productInEl = document.querySelector(`input[name="product_in"]:checked`);
    if (!productScenic) return false;
    const priceScenic = parseFloat(productScenic.getAttribute('data-price'));
    const taxOut = parseFloat(productScenic.getAttribute('data-tax'));

    // 割引状況取得
    const discountValues = [];
    let countDiscount = 0;
    for (let i = 0; i < 10; i++) {
        // 島民割引
        const discountName = `is_discount_1[${i}][]`;
        const discountRadio = document.querySelector(`input[name="${discountName}"]:checked`);
        // ラジオボタンが存在し、かつ選択されている場合
        if (discountRadio && discountRadio.value === '1') {
            countDiscount += 1;
        }
    }
    
    const plan_discount_price = document.getElementById('plan_discount_value');
    const discountToshimaPrice = Number(plan_discount_price.value); // 税込:49200

    /**
     * 計算（※消費税は決済タイミングによる）
     */
    // 往路料金（税込） 
    const flightPriceScenic = (Math.round(priceScenic + priceScenic * (taxOut / 100)));
    // 割引
    const totalDiscount =
        (flightPriceScenic ? countDiscount * Math.round(discountToshimaPrice + discountToshimaPrice * (taxOut / 100)) : 0);
    // 合計
    const totalPrice = flightPriceScenic - totalDiscount;
    // 表示更新
    const customerInfoTable = document.querySelector('#customerInfoPane aside table');
    const paymentOptionsTable = document.querySelector('#paymentOptionsPane aside table');
    if (customerInfoTable && paymentOptionsTable) {
        const tableHtml = `<table class="table table-dark table-sm">
            <tbody>
                <tr>
                    <th scope="row" class="bg-primary">${__t('Out bound flight')}</th>
                    <th class="bg-primary">×${selectedPassengers}</th>
                    <td class="bg-primary text-end">${flightPriceScenic.toLocaleString()} <small>${__t('JPY')}</small></td>
                </tr>
                <tr>
                    <th scope="row" class="bg-primary">${__t('Discount')}</th>
                    <th class="bg-primary">×${countDiscount}</th>
                    <td class="bg-primary text-notice text-end">${(0 - totalDiscount).toLocaleString()} <small>${__t('JPY')}</small></td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th scope="col" class="bg-primary" colspan="2">${__t('Total (tax inc.)')}</th>
                    <td class="bg-primary text-end">${totalPrice.toLocaleString()} <small>${__t('JPY')}</small></td>
                </tr>
            </tfoot>
        </table>`;
        customerInfoTable.innerHTML = tableHtml;
        paymentOptionsTable.innerHTML = tableHtml;
    }

};

const setAsidePriceSpecial = () => {

    // 選択された人数を取得
    const passengersSelect = document.getElementById('passengers');
    const selectedPassengers = parseInt(passengersSelect.value);

    // 選択プラン状況取得
    const productOutEl = document.querySelector(`input[name="product_out"]:checked`);
    const productInEl = document.querySelector(`input[name="product_in"]:checked`);
    if (!productOutEl) return false;
    const priceOut = parseFloat(productOutEl.getAttribute('data-price'));
    const priceIn = productInEl ? parseFloat(productInEl.getAttribute('data-price')) : 0;
    const taxOut = parseFloat(productOutEl.getAttribute('data-tax'));
    const taxIn = productInEl ? parseFloat(productInEl.getAttribute('data-tax')) : 0;
    const discount1Out = parseFloat(productOutEl.getAttribute('data-discount_1'));
    const discount1In = productInEl ? parseFloat(productInEl.getAttribute('data-discount_1')) : 0;

    // 復路利用の有無
    const useInBoundCheckbox = document.getElementById('use_in_bound');
    const isUseInBound = useInBoundCheckbox.checked;

    // 割引状況取得
    const discountValues = [];
    let countDiscount = 0;
    for (let i = 0; i < 10; i++) {
        // 島民割引
        const discountName = `is_discount_1[${i}][]`;
        const discountRadio = document.querySelector(`input[name="${discountName}"]:checked`);
        // ラジオボタンが存在し、かつ選択されている場合
        if (discountRadio && discountRadio.value === '1') {
            countDiscount += 1;
        }
    }
    
    const plan_discount_price = document.getElementById('plan_discount_value');
    const discountToshimaPrice = Number(plan_discount_price.value); // 税込:49200

    /**
     * 計算（※消費税は決済タイミングによる）
     */
    // 往路料金（税込） 
    const flightPriceOut = (Math.round(priceOut + priceOut * (taxOut / 100))) * selectedPassengers;
    // 復路料金（税込） 
    const flightPriceIn = isUseInBound ? (Math.round(priceIn + priceIn * (taxIn / 100))) * selectedPassengers : 0;
    // 割引
    const totalDiscount =
        (flightPriceOut ? countDiscount * Math.round(discountToshimaPrice + discountToshimaPrice * (taxOut / 100)) : 0) +
        (isUseInBound ? (flightPriceIn ? countDiscount * Math.round(discountToshimaPrice + discountToshimaPrice * (taxIn / 100)) : 0) : 0);
    // 合計
    const totalPrice = flightPriceOut + flightPriceIn - totalDiscount;
    // 表示更新
    const customerInfoTable = document.querySelector('#customerInfoPane aside table');
    const paymentOptionsTable = document.querySelector('#paymentOptionsPane aside table');
    if (customerInfoTable && paymentOptionsTable) {
        const tableHtml = `<table class="table table-dark table-sm">
            <tbody>
                <tr>
                    <th scope="row" class="bg-primary">${__t('Out bound flight')}</th>
                    <th class="bg-primary">×${selectedPassengers}</th>
                    <td class="bg-primary text-end">${flightPriceOut.toLocaleString()} <small>${__t('JPY')}</small></td>
                </tr>
                <tr>
                    <th scope="row" class="bg-primary">${__t('In bound flight')}</th>
                    <th class="bg-primary">×${selectedPassengers}</th>
                    <td class="bg-primary text-end">${flightPriceIn.toLocaleString()} <small>${__t('JPY')}</small></td>
                </tr>
                <tr>
                    <th scope="row" class="bg-primary">${__t('Discount')}</th>
                    <th class="bg-primary">×${countDiscount}</th>
                    <td class="bg-primary text-notice text-end">${(0 - totalDiscount).toLocaleString()} <small>${__t('JPY')}</small></td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th scope="col" class="bg-primary" colspan="2">${__t('Total (tax inc.)')}</th>
                    <td class="bg-primary text-end">${totalPrice.toLocaleString()} <small>${__t('JPY')}</small></td>
                </tr>
            </tfoot>
        </table>`;
        customerInfoTable.innerHTML = tableHtml;
        paymentOptionsTable.innerHTML = tableHtml;
    }

};

/**
 * 翻訳関数
 * @param {String} locale *言語
 * @param {String} key *翻訳元
 * @returns 
 */
function __t(key) {
    if (currentLocale === 'en') return key;
    return translationTable[currentLocale][key] || key;
}

// 翻訳の対照表
const translationTable = {
    ja: {
        'Aircraft': '機材',
        'No Plans Found.': 'プランが見つかりませんでした',
        'Select departure, destination, and departure date.': '出発地・到着地・出発日をお選びください',
        'Departure Time': '出発予定時刻',
        'Arrival Time': '到着予定時刻',
        'Timeframe': '時間帯',
        'Duration': '所要時間',
        'Product Name': '商品名',
        'Price': '価格',
        'Price for Toshima-mura Residents': '十島村住民割引後価格',
        '(Tax Inc.)': '（税込）',
        'Remaining Seats': '残りの座席',
        'e.g. ': '例. ',
        'Invalid Phone number.': '電話番号に正しい形式を指定してください。',
        'JPY': '円',
        'Out bound flight': '往路航空引換証',
        'In bound flight': '復路航空引換証',
        'Discount': '十島住民割引',
        'Total (tax inc.)': '合計 (税込)'
    }
};

/**
 * 予約情報：カレンダー表示
 */
const setBookCal = (prefix) => {

    // 今日の日付入力要素
    const date_tomorrow = document.getElementById('date_tomorrow');
    // カレンダー領域のdata-date属性の日付（明日）
    const tomorrow = date_tomorrow.value;
    const today = new Date(new Date(tomorrow).getTime() - 24 * 60 * 60 * 1000).toISOString().split('T')[0];

    // 事前に変数で設定を用意
    const tempusDominusConfig = {
        display: {
            components: {
                decades: true,
                year: true,
                month: true,
                date: true,
                hours: false,
                minutes: false,
                seconds: false,
            },
        },
        localization: {
            locale: currentLocale === 'ja' ? 'ja-JP' : 'en',
            format: 'yyyy-MM-dd',
            dayViewHeaderFormat: { month: 'long', year: 'numeric' },
        },
        restrictions: {
            minDate: today,
        },
    };

    if(prefix == 'scenic'){

        const departure_date_scenic_cal = document.getElementById('departure_date_scenic_cal');
        if (!departure_date_scenic_cal) return;
        sharedCals.departure_date_scenic_cal_cal = new TempusDominus(departure_date_scenic_cal, tempusDominusConfig);

        departure_date_scenic_cal.addEventListener(Namespace.events.change, (e) => {
            // 往路のカレンダーの選択日時を、復路のカレンダーの最小日時に設定           
            calChanges(prefix);
        });

        
    }else if(prefix == 'special'){
        // 往路：カレンダー領域を取得
        const departure_date_out_cal = document.getElementById('departure_date_out_cal');
        // 往路：カレンダー日付入力要素
        const departure_date_out = document.getElementById('departure_date_out');
        // 復路：カレンダー領域を取得
        const departure_date_in_cal = document.getElementById('departure_date_in_cal');
        // 復路：カレンダー日付入力要素
        const departure_date_in = document.getElementById('departure_date_in');

        if (!(departure_date_out_cal && departure_date_in_cal)) return;


        // カレンダーUIを新しい設定で初期化
        sharedCals.departure_date_out_cal_cal = new TempusDominus(departure_date_out_cal, tempusDominusConfig);
        sharedCals.departure_date_in_cal_cal = new TempusDominus(departure_date_in_cal, tempusDominusConfig);

        // 往路：選択日付変更時
        departure_date_out_cal.addEventListener(Namespace.events.change, (e) => {
            // 往路のカレンダーの選択日時を、復路のカレンダーの最小日時に設定
            sharedCals.departure_date_in_cal_cal.updateOptions({
                restrictions: {
                    minDate: departure_date_out.value,
                },
            });
            calChanges('out')
        });

        // 復路：選択日付変更時
        departure_date_in_cal.addEventListener(Namespace.events.change, (e) => {
            // 日付ボタン・プラン更新
            calChanges('in')
        });
    }

    function calChanges(prefix){
        //window.setTimeout(function () {
            // 商品IDと出発時間の値を空にする
            const product_id_input = document.querySelector('[name="product_id_'+prefix+'"]');
            const departure_time_input = document.querySelector('[name="departure_time_'+prefix+'"]');
            const arrival_time_input = document.querySelector('[name="arrival_time_'+prefix+'"]');
            product_id_input.value = '';
            departure_time_input.value = '';
            arrival_time_input.value = '';
            // プラン検索
            createPlans(prefix);
            createDates(prefix);
            setSelectedDate(prefix);
       //}, 1);
    }


};

// 外部参照できるカレンダー変数をプロパティに格納
const sharedCals = {
    departure_date_out_cal_cal: null,
    departure_date_in_cal_cal: null,
    departure_date_scenic_cal_cal: null,
};

/**
 * 予約情報：[EL] 予約日の選択肢（ボタン）選択時の処理
 */
const setSelectedDate = (prefix) => {
    // 選択領域
    const departureDatesContainer = document.getElementById('departure_date_' + prefix + 's');
    // 選択時のイベントリスナー
    const departureDateBtns = document.getElementsByName('departure_date_btn_' + prefix);
    departureDateBtns.forEach(function (departureDateBtn) {
        departureDateBtn.addEventListener('change', function (event) {
            // 出発日の値を書き換え
            const departure_date_input = document.getElementById('departure_date_' + prefix);
            departure_date_input.value = event.target.value;
            // プランの選択リセット
            const search_products = document.getElementById('search_' + prefix + '_products');
            // 商品IDと出発時間の値を空にする
            const product_id_input = document.querySelector('[name="product_id_' + prefix + '"]');
            const departure_time_input = document.querySelector('[name="departure_time_' + prefix + '"]');
            const arrival_time_input = document.querySelector('[name="arrival_time_' + prefix + '"]');
            product_id_input.value = '';
            departure_time_input.value = '';
            arrival_time_input.value = '';
            // 全てのプランを取得
            const listItems = search_products.querySelectorAll('.list-group-item');
            // 全てのプランの選択を解除
            let listItemLast = true;
            listItems.forEach((listItem, index, array) => {
                const radioButton = listItem.querySelector('input[type="radio"]');
                listItem.classList.add('d-none');
                // プラン領域の表示書き換え
                if (radioButton) {
                    radioButton.checked = false;
                    if (radioButton.dataset.date === event.target.value) {
                        listItem.classList.remove('d-none');
                        listItemLast = false;
                    } else {
                        listItem.classList.add('d-none');
                    }
                }
                // 該当プランがない場合は「プランが見つかりませんでした」と表示
                if (listItemLast && index === array.length - 1) {
                    listItem.classList.remove('d-none');
                }
            });
            // 往路ユニーク処理
            if (prefix === 'out') {
                setMinDateCal(event);
            }
            // フィールドセット単位での操作可否切り替え                    
            switchDisabledBookFieldset(prefix);
            // 価格の更新
            setAsidePrice(prefix);
        });
    });
};

/**
 * 往路のカレンダーの選択日時を、復路のカレンダーの最小日時に設定
 */
const setMinDateCal = (event) => {
    sharedCals.departure_date_in_cal_cal.updateOptions({
        restrictions: {
            minDate: new Date(event.target.value).toISOString(),
        },
    });
    const departure_date_in = document.getElementById('departure_date_in');
    if (new Date(event.target.value) > new Date(departure_date_in.value)) {
        departure_date_in.value = event.target.value;
        sharedCals.departure_date_in_cal_cal.updateOptions({
            restrictions: {
                minDate: new Date(event.target.value).toISOString(),
            },
        });
        createDates('in');
    }
};

/**
 * 予約情報：プランリストを生成
 */
const createPlans = (prefix) => {
    // 設定の変更と取得のタイミングをずらす
    
    return new Promise((resolve, reject) => {
    //window.setTimeout(function () {
        // カレンダー日付入力要素
        const departure = getValByName('search_from_' + prefix); //出発地
        const arrival = getValByName('search_to_' + prefix); //到着地
        const passengers = getValByName('passengers'); //人数
        const product_id = getValByName('product_id_' + prefix);　//プラン選択
        const departure_date = getValByName('departure_date_' + prefix); //出発日
        const departure_time = getValByName('departure_time_' + prefix); //出発予定時刻
        const arrival_time = getValByName('arrival_time_' + prefix); //未使用　到着予定時刻
        const departure_date_begin = getValByName('departure_date_' + prefix + '_begin');　//出発日ボタン開始日
        const search_products = document.getElementById('search_' + prefix + '_products'); //プラン選択div
        const departure_date_out = getValByName('departure_date_out'); //復路出発日
        const departure_time_out = getValByName('departure_time_out'); //復路予定時刻
        const arrival_time_out = getValByName('arrival_time_out'); //未使用　復路到着予定時刻

        const service = prefix == 'scenic' ? 2 : 1;
    
        //出発予定時刻、到着地、人数、出発日、出発日ボタン開始日があれば
        if ( (prefix == 'scenic' || departure && arrival) && passengers && departure_date && departure_date_begin) {
            // 商品検索のためのリクエストをサーバーに送信
            axios.post('/search-products', {
                service: service,
                departure: departure,
                arrival: arrival,
                passengers: passengers,
                product_id: product_id,
                departure_date: departure_date,
                departure_date_begin: departure_date_begin,
                departure_time: departure_time,
                departure_date_out: departure_date_out,
                departure_time_out: departure_time_out,
                prefix: prefix,
                locale: currentLocale
            }).then(function (response) {
                search_products.innerHTML = response.data;
                // プランが未選択時の対応
                const productRadios = document.querySelectorAll('input[name="product_' + prefix + '"]');
                const productIdInput = document.querySelector('input[name="product_id_' + prefix + '"]');
                const productTimeOutInput = document.querySelector('input[name="departure_time_' + prefix + '"]');
                const productTimeInInput = document.querySelector('input[name="arrival_time_' + prefix + '"]');
                if (![...productRadios].some(radio => radio.checked)) {
                    productIdInput.value = '';
                    productTimeOutInput.value = '';
                    productTimeInInput.value = '';
                }
                // 表示の更新
                switchDisabledBookFieldset(prefix);
                // 価格の更新
                setAsidePrice(prefix);
                resolve();
            }).catch(function (error) {
                reject(error);
            });
        } else {
            // 商品IDと出発時間の値を空にする
            const product_id_input = document.querySelector('[name="product_id_out"]');
            const departure_time_out_input = document.querySelector('[name="departure_time_out"]');
            const departure_time_in_input = document.querySelector('[name="departure_time_in"]');
            product_id_input.value = '';
            departure_time_out_input.value = '';
            departure_time_in_input.value = '';
            search_products.innerHTML = '';
            // 「選択してください」を表示
            const item = document.createElement('li');
            item.classList.add('list-group-item', 'text-center', 'fw-bold');
            item.innerHTML = '<div class="form-check"><label class="justify-content-center">' + __t('Select departure, destination, and departure date.') + '</label></div>';
            search_products.appendChild(item);
            // 表示の更新
            switchDisabledBookFieldset(prefix);
            // 価格の更新
            setAsidePrice(prefix);
            resolve();
        }
    //}, 1);
    });
};

/**
 * フィールドセット単位での操作可否切り替え
 */
const switchDisabledBookFieldset = (prefix) => {

    if(prefix == 'scenic'){
        switchDisabledBookFieldsetScenic();
    }else{
        switchDisabledBookFieldsetSpecial();
    }
};

const switchDisabledBookFieldsetScenic = () =>{
    const actionNextBookEl = document.querySelector('#bookingInfoPane button.action-next');

    const scenicPlanEl = document.getElementById('scenic_plan');
    //const inFromToEl = document.getElementById('in_fromto');
    //const inPlanEl = document.getElementById('in_plan');
    const agreeEl = document.getElementById('agree');

    const passengers = getValByName('passengers');

    //const departure_out = getValByName('search_from_out');
    //const arrival_out = getValByName('search_to_out');
    const productID_scenic = getValByName('product_id_scenic');

    //const departure_in = getValByName('search_from_in');
    //const arrival_in = getValByName('search_to_in');
    //const productID_in = getValByName('product_id_scenic');

    // 同意事項のチェックボックスを取得
    const checkAgrees = document.querySelectorAll('input[type="checkbox"][name^="is_agree_"]');
    // すべての同意事項がチェックされているかどうかを判定
    const isCheckAgrees = Array.from(checkAgrees).every(checkAgree => checkAgree.checked);
    // 復路利用か
    //const isUseInBoundCheck = isUseInBound();

    // 出発地、到着地、乗客が選択されている場合
    if (isCheckAgrees && passengers && productID_scenic) {
        scenicPlanEl.disabled = false;
        agreeEl.disabled = false;
        actionNextBookEl.disabled = false;
    } else if (passengers && productID_scenic) {
        scenicPlanEl.disabled = false;
        agreeEl.disabled = false;
        actionNextBookEl.disabled = true;
    } else if (passengers) {
        scenicPlanEl.disabled = false;
        agreeEl.disabled = true;
        actionNextBookEl.disabled = true;
    } else {
        scenicPlanEl.disabled = false;
        agreeEl.disabled = true;
        actionNextBookEl.disabled = true;
    }
}

const switchDisabledBookFieldsetSpecial = () =>{
    const actionNextBookEl = document.querySelector('#bookingInfoPane button.action-next');

    const outPlanEl = document.getElementById('out_plan');
    const inFromToEl = document.getElementById('in_fromto');
    const inPlanEl = document.getElementById('in_plan');
    const agreeEl = document.getElementById('agree');

    const passengers = getValByName('passengers');

    const departure_out = getValByName('search_from_out');
    const arrival_out = getValByName('search_to_out');
    const productID_out = getValByName('product_id_out');

    const departure_in = getValByName('search_from_in');
    const arrival_in = getValByName('search_to_in');
    const productID_in = getValByName('product_id_in');

    // 同意事項のチェックボックスを取得
    const checkAgrees = document.querySelectorAll('input[type="checkbox"][name^="is_agree_"]');
    // すべての同意事項がチェックされているかどうかを判定
    const isCheckAgrees = Array.from(checkAgrees).every(checkAgree => checkAgree.checked);
    // 復路利用か
    const isUseInBoundCheck = isUseInBound();

    // 出発地、到着地、乗客が選択されている場合
    if (isCheckAgrees && passengers && departure_out && arrival_out && productID_out && departure_in && arrival_in && productID_in) {
        outPlanEl.disabled = false;
        inFromToEl.disabled = false;
        inPlanEl.disabled = !isUseInBoundCheck ?? false;
        agreeEl.disabled = false;
        actionNextBookEl.disabled = false;
    } else if (isCheckAgrees && passengers && departure_out && arrival_out && productID_out && !isUseInBoundCheck) {
        outPlanEl.disabled = false;
        inFromToEl.disabled = false;
        inPlanEl.disabled = true;
        agreeEl.disabled = false;
        actionNextBookEl.disabled = false;
    } else if (passengers && departure_out && arrival_out && productID_out && departure_in && arrival_in && productID_in) {
        outPlanEl.disabled = false;
        inFromToEl.disabled = false;
        inPlanEl.disabled = !isUseInBoundCheck ?? false;
        agreeEl.disabled = false;
        actionNextBookEl.disabled = true;
    } else if (passengers && departure_out && arrival_out && productID_out) {
        outPlanEl.disabled = false;
        inFromToEl.disabled = false;
        inPlanEl.disabled = !isUseInBoundCheck ?? false;
        agreeEl.disabled = isUseInBoundCheck ?? true;
        actionNextBookEl.disabled = true;
    } else if (passengers && departure_out && arrival_out) {
        outPlanEl.disabled = false;
        inFromToEl.disabled = true;
        inPlanEl.disabled = true;
        agreeEl.disabled = true;
        actionNextBookEl.disabled = true;
    } else {
        outPlanEl.disabled = true;
        inFromToEl.disabled = true;
        inPlanEl.disabled = true;
        agreeEl.disabled = true;
        actionNextBookEl.disabled = true;
    }
}

/**
 * 「復路を利用する」がチェックされているか判定
 */
const isUseInBound = () => {
    const useInBoundCheckbox = document.querySelector('[name="use_in_bound"]');
    return useInBoundCheckbox.checked;
};

/**
 * 予約情報：[EL] プラン選択時の処理
 */
const setSelectedPlan = (prefix) => {
    const search_products = document.getElementById('search_' + prefix + '_products');
    const product_id_input = document.querySelector('[name="product_id_' + prefix + '"]');
    const departure_time_input = document.querySelector('[name="departure_time_' + prefix + '"]');
    const arrival_time_input = document.querySelector('[name="arrival_time_' + prefix + '"]');
    // ラジオボタンが変化した際のイベントリスナーを設定
    search_products.addEventListener('change', (event) => {
        if (event.target.type === 'radio' && event.target.checked) {
            // 値をセット
            const selectedProduct = event.target;
            product_id_input.value = selectedProduct.value;
            departure_time_input.value = selectedProduct.dataset.departure;
            arrival_time_input.value = selectedProduct.dataset.arrival;
            const aircraft_id = selectedProduct.dataset.aircraft_id;

            // 表示の更新
            switchDisabledBookFieldset(prefix);
            // 価格の更新
            setAsidePrice(prefix);
            setCustomerFieldByPassenger(aircraft_id);
        }
    });
    if(prefix === 'out'|| prefix === 'in'){
        search_products.addEventListener('change', (event) => {
            const plan_discount_price = document.getElementById('plan_discount_value');
            if (event.target.type === 'radio' && event.target.checked) {
                const selectedProduct = event.target;
                plan_discount_price.value = Number(selectedProduct.dataset.discount_price); // 税込:49200
            }

        });    
    }
    if (prefix === 'out') {
        search_products.addEventListener('change', (event) => {
            // 日付ボタン・プラン更新
            window.setTimeout(function () {
                // 商品IDと出発時間の値を空にする
                const product_id_input = document.querySelector('[name="product_id_in"]');
                const departure_time_input = document.querySelector('[name="departure_time_in"]');
                const arrival_time_input = document.querySelector('[name="arrival_time_in"]');
                product_id_input.value = '';
                departure_time_input.value = '';
                arrival_time_input.value = '';
                // プラン検索
                createPlans('in');
                createDates('in');
                setSelectedDate('in');
                // 価格の更新
                setAsidePrice(prefix);
            }, 1);
        });
    }
};

/**
 * 予約情報：[EL] 往路：「路線」「人数」「日付」変更時にプランを検索
 */
const setcreatePlansEventListener = (prefix) => {
    // 往路の入力要素を取得
    const outFromToElements = document.querySelectorAll('#' + prefix + '_fromto input, #' + prefix + '_fromto select');
    // 各要素に変更を監視するイベントリスナーを設定
    outFromToElements.forEach(function (element) {
        element.addEventListener('change', function () {
            createPlans(prefix);
        });
    });
};

/**
 * 予約情報：`お客様情報`を人数分だけ出力
 */
const setCustomerFieldByPassenger = (aircraft_id = 1) => {

    // 選択された人数のselect要素を取得
    const passengersSelect = document.getElementById('passengers');

    // 選択された人数を取得
    const selectedPassengers = parseInt(passengersSelect.value);

    // すべての行を非表示、無効化にする
    const customerFieldsets = document.querySelectorAll('[id^="customer_"]');
    customerFieldsets.forEach(customerFieldset => {
        customerFieldset.classList.add('d-none');
        const customerMainFieldset = customerFieldset.querySelector('fieldset.book__customer__main');
        customerMainFieldset.disabled = !!(customerMainFieldset);
    });

    // 選択された人数に応じて行を表示する
    for (let i = 1; i <= selectedPassengers; i++) {
        const customerFieldset = document.getElementById(`customer_${i}`);
        if (customerFieldset) {
            customerFieldset.classList.remove('d-none');
            const customerMainFieldset = customerFieldset.querySelector('fieldset.book__customer__main');
            customerMainFieldset.disabled = !(customerMainFieldset);
        }

        if(aircraft_id == 2){

            if(i >= 2){
                const islander_opt = customerFieldset.querySelectorAll('.opt_islander');
                const changeText = customerFieldset.querySelector('.text-info').innerText;
                islander_opt.forEach(function(element) {
                    // 各要素に対する処理をここに記述
                     element.classList.remove('text-danger');
                     element.classList.add('text-info');
                     element.textContent = changeText;
                });
            }

        }else{
            const islander_opt = customerFieldset.querySelectorAll('.opt_islander');
            const changeText = customerFieldset.querySelector('.text-danger').innerText;
            islander_opt.forEach(function(element) {
                // 各要素に対する処理をここに記述
                 element.classList.add('text-danger');
                 element.classList.remove('text-info');
                 element.textContent = changeText;
            });

        }
    }
    const allSeatRadios = document.querySelectorAll(`#customerInfoPane .book__customer__seat input[type="radio"]`);
    allSeatRadios.forEach(function (thisRadio) {
        thisRadio.checked = false;
    });
    
};

const setUrl = (key, value) => {

    const url = new URL(location.href);
    const params = new URLSearchParams(url.search);

    if (params.has(key)) {
        if (params.get(key) !== value) {
            params.set(key, value);
        }
    } else {
        // 元のURLにserviceパラメータが含まれていない場合、新しく追加する
        params.append(key, value);
    }
    url.search = params.toString();
    history.pushState(null, null, url.toString());

}

export {createDates,getValByName, getDayOfWeek, setAsidePrice,__t,setBookCal,setSelectedDate,createPlans, switchDisabledBookFieldset, setSelectedPlan,setcreatePlansEventListener, setCustomerFieldByPassenger};